/**	
 * https://github.com/maxlutzfl/googlemapsjqueryplugin
 */ 

(function($) {

	var init_map = function() {
		var map_data = $('[data-google-map]').attr('data-google-map');
		map_data = JSON.parse(map_data);
		console.log(map_data.markers);

		$('[data-google-map]').bcore_google_maps({
			api_key: theme.googleapi,
			lat: 0,
			lng: 0,
			zoom: 13,
			marker_icon: theme.map_marker,
			marker_icon_w: 36,
			marker_icon_h: 46,
			styles: null,
			cluster: false,
			markers: map_data.markers,
			infowindow_template: function(infowindow_data) {
				console.log(infowindow_data);
			},
			on_infowindow_open: function(cb) {  },
			on_infowindow_close: function(cb) {  },
			on_marker_click: function(cb) {  }
		});
	}

	if ( $('[data-google-map]').length > 0 ) {
		init_map();
	}

})(jQuery);