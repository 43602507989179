;(function($) {

	var isMobile = function() {
		return ($('html').hasClass('app_touchevents')) ? true : false;
	}

	var initSkrollr = function() {
		if (!isMobile()) {
			var s = skrollr.init({
				forceHeight: false,
				smoothScrolling: true,
				smoothScrollingDuration: 200
			});
		} else {
			$('html').addClass('no-skrollr');
		}
	}

	$(document).ready(function() {

		initSkrollr();

		$('html').removeClass('no-js').addClass('js');

		$('.post-content a[href$=".jpg"]').magnificPopup({
			mainClass: 'mfp-fade',
			type: 'image',
			gallery: {
				enabled: true
			}
		});

		$('.js-modal').magnificPopup({
			mainClass: 'mfp-fade',
			type: 'inline',
			preloader: false,
			modal: false,
			gallery: {
				enabled: true
			} 
		});

		$(document).on('click', '.popup-modal-dismiss', function(e) {
			e.preventDefault();
			$.magnificPopup.close();
		});

	});

	$('.hero-section__form__header').click(function() {
		$('.hero-section__form__main').slideToggle();
	});

})(jQuery);