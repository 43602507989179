;(function($) {

	var lazy_load_images = function(e, slick, image, imageSource) {
		$(image).parent().css({
			'background-image': 'url(' + imageSource + ')'
		});
	}

	$('.js-testimonials').slick({
		autoplay: true,
		autoplaySpeed: 5000,
		speed: 1000,
		fade: true,
		dots: true,
		arrows: false,
		pauseOnFocus: false,
		pauseOnHover: false,
		pauseOnDotsHover: false,
	});

	$('.js-backgrounds').on('lazyLoaded', lazy_load_images);

	$('.js-backgrounds').slick({
		autoplay: true,
		autoplaySpeed: 5000,
		speed: 1000,
		fade: true,
		dots: false,
		arrows: false,
		pauseOnFocus: false,
		pauseOnHover: false,
		pauseOnDotsHover: false,
	});

})(jQuery);